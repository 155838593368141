@font-face {
font-family: "uicons-regular-rounded";
src: url("../webfonts/uicons-regular-rounded.eot#iefix") format("embedded-opentype"),
url("../webfonts/uicons-regular-rounded.woff2") format("woff2"),
url("../webfonts/uicons-regular-rounded.woff") format("woff");
}

    i[class^="fi-rr-"]:before, i[class*=" fi-rr-"]:before, span[class^="fi-rr-"]:before, span[class*="fi-rr-"]:before {
font-family: uicons-regular-rounded !important;
font-style: normal;
font-weight: normal !important;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

        .fi-rr-a:before {
    content: "\f101";
    }
        .fi-rr-ad:before {
    content: "\f102";
    }
        .fi-rr-add-document:before {
    content: "\f103";
    }
        .fi-rr-add-folder:before {
    content: "\f104";
    }
        .fi-rr-add:before {
    content: "\f105";
    }
        .fi-rr-address-book:before {
    content: "\f106";
    }
        .fi-rr-air-conditioner:before {
    content: "\f107";
    }
        .fi-rr-air-freshener:before {
    content: "\f108";
    }
        .fi-rr-alarm-clock:before {
    content: "\f109";
    }
        .fi-rr-alarm-exclamation:before {
    content: "\f10a";
    }
        .fi-rr-alarm-plus:before {
    content: "\f10b";
    }
        .fi-rr-alarm-snooze:before {
    content: "\f10c";
    }
        .fi-rr-align-justify:before {
    content: "\f10d";
    }
        .fi-rr-align-left:before {
    content: "\f10e";
    }
        .fi-rr-ambulance:before {
    content: "\f10f";
    }
        .fi-rr-angle-circle-down:before {
    content: "\f110";
    }
        .fi-rr-angle-circle-left:before {
    content: "\f111";
    }
        .fi-rr-angle-circle-right:before {
    content: "\f112";
    }
        .fi-rr-angle-circle-up:before {
    content: "\f113";
    }
        .fi-rr-angle-double-left:before {
    content: "\f114";
    }
        .fi-rr-angle-double-right:before {
    content: "\f115";
    }
        .fi-rr-angle-double-small-down:before {
    content: "\f116";
    }
        .fi-rr-angle-double-small-left:before {
    content: "\f117";
    }
        .fi-rr-angle-double-small-right:before {
    content: "\f118";
    }
        .fi-rr-angle-double-small-up:before {
    content: "\f119";
    }
        .fi-rr-angle-down:before {
    content: "\f11a";
    }
        .fi-rr-angle-left:before {
    content: "\f11b";
    }
        .fi-rr-angle-right:before {
    content: "\f11c";
    }
        .fi-rr-angle-small-down:before {
    content: "\f11d";
    }
        .fi-rr-angle-small-left:before {
    content: "\f11e";
    }
        .fi-rr-angle-small-right:before {
    content: "\f11f";
    }
        .fi-rr-angle-small-up:before {
    content: "\f120";
    }
        .fi-rr-angle-square-down:before {
    content: "\f121";
    }
        .fi-rr-angle-square-left:before {
    content: "\f122";
    }
        .fi-rr-angle-square-right:before {
    content: "\f123";
    }
        .fi-rr-angle-square-up:before {
    content: "\f124";
    }
        .fi-rr-angle-up:before {
    content: "\f125";
    }
        .fi-rr-angry:before {
    content: "\f126";
    }
        .fi-rr-apple-whole:before {
    content: "\f127";
    }
        .fi-rr-apps-add:before {
    content: "\f128";
    }
        .fi-rr-apps-delete:before {
    content: "\f129";
    }
        .fi-rr-apps-sort:before {
    content: "\f12a";
    }
        .fi-rr-apps:before {
    content: "\f12b";
    }
        .fi-rr-archive:before {
    content: "\f12c";
    }
        .fi-rr-arrow-alt-circle-down:before {
    content: "\f12d";
    }
        .fi-rr-arrow-alt-circle-left:before {
    content: "\f12e";
    }
        .fi-rr-arrow-alt-circle-right:before {
    content: "\f12f";
    }
        .fi-rr-arrow-alt-circle-up:before {
    content: "\f130";
    }
        .fi-rr-arrow-alt-down:before {
    content: "\f131";
    }
        .fi-rr-arrow-alt-from-bottom:before {
    content: "\f132";
    }
        .fi-rr-arrow-alt-from-left:before {
    content: "\f133";
    }
        .fi-rr-arrow-alt-from-right:before {
    content: "\f134";
    }
        .fi-rr-arrow-alt-from-top:before {
    content: "\f135";
    }
        .fi-rr-arrow-alt-left:before {
    content: "\f136";
    }
        .fi-rr-arrow-alt-right:before {
    content: "\f137";
    }
        .fi-rr-arrow-alt-square-down:before {
    content: "\f138";
    }
        .fi-rr-arrow-alt-square-left:before {
    content: "\f139";
    }
        .fi-rr-arrow-alt-square-right:before {
    content: "\f13a";
    }
        .fi-rr-arrow-alt-square-up:before {
    content: "\f13b";
    }
        .fi-rr-arrow-alt-to-bottom:before {
    content: "\f13c";
    }
        .fi-rr-arrow-alt-to-left:before {
    content: "\f13d";
    }
        .fi-rr-arrow-alt-to-right:before {
    content: "\f13e";
    }
        .fi-rr-arrow-alt-to-top:before {
    content: "\f13f";
    }
        .fi-rr-arrow-alt-up:before {
    content: "\f140";
    }
        .fi-rr-arrow-circle-down:before {
    content: "\f141";
    }
        .fi-rr-arrow-circle-left:before {
    content: "\f142";
    }
        .fi-rr-arrow-circle-right:before {
    content: "\f143";
    }
        .fi-rr-arrow-circle-up:before {
    content: "\f144";
    }
        .fi-rr-arrow-down:before {
    content: "\f145";
    }
        .fi-rr-arrow-from-bottom:before {
    content: "\f146";
    }
        .fi-rr-arrow-from-left:before {
    content: "\f147";
    }
        .fi-rr-arrow-from-right:before {
    content: "\f148";
    }
        .fi-rr-arrow-from-top:before {
    content: "\f149";
    }
        .fi-rr-arrow-left:before {
    content: "\f14a";
    }
        .fi-rr-arrow-right:before {
    content: "\f14b";
    }
        .fi-rr-arrow-small-down:before {
    content: "\f14c";
    }
        .fi-rr-arrow-small-left:before {
    content: "\f14d";
    }
        .fi-rr-arrow-small-right:before {
    content: "\f14e";
    }
        .fi-rr-arrow-small-up:before {
    content: "\f14f";
    }
        .fi-rr-arrow-square-down:before {
    content: "\f150";
    }
        .fi-rr-arrow-square-left:before {
    content: "\f151";
    }
        .fi-rr-arrow-square-right:before {
    content: "\f152";
    }
        .fi-rr-arrow-square-up:before {
    content: "\f153";
    }
        .fi-rr-arrow-to-bottom:before {
    content: "\f154";
    }
        .fi-rr-arrow-to-left:before {
    content: "\f155";
    }
        .fi-rr-arrow-to-right:before {
    content: "\f156";
    }
        .fi-rr-arrow-to-top:before {
    content: "\f157";
    }
        .fi-rr-arrow-trend-down:before {
    content: "\f158";
    }
        .fi-rr-arrow-trend-up:before {
    content: "\f159";
    }
        .fi-rr-arrow-up-from-square:before {
    content: "\f15a";
    }
        .fi-rr-arrow-up-left-from-circle:before {
    content: "\f15b";
    }
        .fi-rr-arrow-up-left:before {
    content: "\f15c";
    }
        .fi-rr-arrow-up-right-and-arrow-down-left-from-center:before {
    content: "\f15d";
    }
        .fi-rr-arrow-up-right-from-square:before {
    content: "\f15e";
    }
        .fi-rr-arrow-up-right:before {
    content: "\f15f";
    }
        .fi-rr-arrow-up-square-triangle:before {
    content: "\f160";
    }
        .fi-rr-arrow-up-to-dotted-line:before {
    content: "\f161";
    }
        .fi-rr-arrow-up:before {
    content: "\f162";
    }
        .fi-rr-arrows-alt-h:before {
    content: "\f163";
    }
        .fi-rr-arrows-alt-v:before {
    content: "\f164";
    }
        .fi-rr-arrows-alt:before {
    content: "\f165";
    }
        .fi-rr-arrows-cross:before {
    content: "\f166";
    }
        .fi-rr-arrows-from-dotted-line:before {
    content: "\f167";
    }
        .fi-rr-arrows-from-line:before {
    content: "\f168";
    }
        .fi-rr-arrows-h-copy:before {
    content: "\f169";
    }
        .fi-rr-arrows-h:before {
    content: "\f16a";
    }
        .fi-rr-arrows-repeat-1:before {
    content: "\f16b";
    }
        .fi-rr-arrows-repeat:before {
    content: "\f16c";
    }
        .fi-rr-arrows-to-dotted-line:before {
    content: "\f16d";
    }
        .fi-rr-arrows-to-line:before {
    content: "\f16e";
    }
        .fi-rr-arrows:before {
    content: "\f16f";
    }
        .fi-rr-assept-document:before {
    content: "\f170";
    }
        .fi-rr-assistive-listening-systems:before {
    content: "\f171";
    }
        .fi-rr-asterik:before {
    content: "\f172";
    }
        .fi-rr-at:before {
    content: "\f173";
    }
        .fi-rr-aubergine:before {
    content: "\f174";
    }
        .fi-rr-avocado:before {
    content: "\f175";
    }
        .fi-rr-b:before {
    content: "\f176";
    }
        .fi-rr-baby-carriage:before {
    content: "\f177";
    }
        .fi-rr-backpack:before {
    content: "\f178";
    }
        .fi-rr-bacon:before {
    content: "\f179";
    }
        .fi-rr-badge-check:before {
    content: "\f17a";
    }
        .fi-rr-badge-dollar:before {
    content: "\f17b";
    }
        .fi-rr-badge-percent:before {
    content: "\f17c";
    }
        .fi-rr-badge-sheriff:before {
    content: "\f17d";
    }
        .fi-rr-badge:before {
    content: "\f17e";
    }
        .fi-rr-bags-shopping:before {
    content: "\f17f";
    }
        .fi-rr-bahai:before {
    content: "\f180";
    }
        .fi-rr-balance-scale-left:before {
    content: "\f181";
    }
        .fi-rr-balance-scale-right:before {
    content: "\f182";
    }
        .fi-rr-ball:before {
    content: "\f183";
    }
        .fi-rr-balloons:before {
    content: "\f184";
    }
        .fi-rr-ballot:before {
    content: "\f185";
    }
        .fi-rr-ban:before {
    content: "\f186";
    }
        .fi-rr-band-aid:before {
    content: "\f187";
    }
        .fi-rr-bank:before {
    content: "\f188";
    }
        .fi-rr-barber-shop:before {
    content: "\f189";
    }
        .fi-rr-bars-progress:before {
    content: "\f18a";
    }
        .fi-rr-baseball-alt:before {
    content: "\f18b";
    }
        .fi-rr-baseball:before {
    content: "\f18c";
    }
        .fi-rr-basket:before {
    content: "\f18d";
    }
        .fi-rr-basketball:before {
    content: "\f18e";
    }
        .fi-rr-bed-alt:before {
    content: "\f18f";
    }
        .fi-rr-bed:before {
    content: "\f190";
    }
        .fi-rr-beer:before {
    content: "\f191";
    }
        .fi-rr-bell-ring:before {
    content: "\f192";
    }
        .fi-rr-bell-school:before {
    content: "\f193";
    }
        .fi-rr-bell:before {
    content: "\f194";
    }
        .fi-rr-bike:before {
    content: "\f195";
    }
        .fi-rr-biking-mountain:before {
    content: "\f196";
    }
        .fi-rr-biking:before {
    content: "\f197";
    }
        .fi-rr-billiard:before {
    content: "\f198";
    }
        .fi-rr-blinds-open:before {
    content: "\f199";
    }
        .fi-rr-blinds-raised:before {
    content: "\f19a";
    }
        .fi-rr-blinds:before {
    content: "\f19b";
    }
        .fi-rr-bold:before {
    content: "\f19c";
    }
        .fi-rr-bolt:before {
    content: "\f19d";
    }
        .fi-rr-book-alt:before {
    content: "\f19e";
    }
        .fi-rr-book-arrow-right:before {
    content: "\f19f";
    }
        .fi-rr-book-arrow-up:before {
    content: "\f1a0";
    }
        .fi-rr-book-bookmark:before {
    content: "\f1a1";
    }
        .fi-rr-book-open-cover:before {
    content: "\f1a2";
    }
        .fi-rr-book-open-reader:before {
    content: "\f1a3";
    }
        .fi-rr-book:before {
    content: "\f1a4";
    }
        .fi-rr-bookmark:before {
    content: "\f1a5";
    }
        .fi-rr-books:before {
    content: "\f1a6";
    }
        .fi-rr-bottle:before {
    content: "\f1a7";
    }
        .fi-rr-bow-arrow:before {
    content: "\f1a8";
    }
        .fi-rr-bowling-ball:before {
    content: "\f1a9";
    }
        .fi-rr-bowling-pins:before {
    content: "\f1aa";
    }
        .fi-rr-bowling:before {
    content: "\f1ab";
    }
        .fi-rr-box-alt:before {
    content: "\f1ac";
    }
        .fi-rr-box-ballot:before {
    content: "\f1ad";
    }
        .fi-rr-box-check:before {
    content: "\f1ae";
    }
        .fi-rr-box-tissue:before {
    content: "\f1af";
    }
        .fi-rr-box:before {
    content: "\f1b0";
    }
        .fi-rr-boxes:before {
    content: "\f1b1";
    }
        .fi-rr-boxing-glove:before {
    content: "\f1b2";
    }
        .fi-rr-braille:before {
    content: "\f1b3";
    }
        .fi-rr-bread-slice:before {
    content: "\f1b4";
    }
        .fi-rr-bread:before {
    content: "\f1b5";
    }
        .fi-rr-briefcase:before {
    content: "\f1b6";
    }
        .fi-rr-broccoli:before {
    content: "\f1b7";
    }
        .fi-rr-broom:before {
    content: "\f1b8";
    }
        .fi-rr-browser:before {
    content: "\f1b9";
    }
        .fi-rr-brush:before {
    content: "\f1ba";
    }
        .fi-rr-bug:before {
    content: "\f1bb";
    }
        .fi-rr-building:before {
    content: "\f1bc";
    }
        .fi-rr-bulb:before {
    content: "\f1bd";
    }
        .fi-rr-bullseye-pointer:before {
    content: "\f1be";
    }
        .fi-rr-bullseye:before {
    content: "\f1bf";
    }
        .fi-rr-burrito:before {
    content: "\f1c0";
    }
        .fi-rr-bus-alt:before {
    content: "\f1c1";
    }
        .fi-rr-bus:before {
    content: "\f1c2";
    }
        .fi-rr-business-time:before {
    content: "\f1c3";
    }
        .fi-rr-butterfly:before {
    content: "\f1c4";
    }
        .fi-rr-c:before {
    content: "\f1c5";
    }
        .fi-rr-cake-birthday:before {
    content: "\f1c6";
    }
        .fi-rr-cake-wedding:before {
    content: "\f1c7";
    }
        .fi-rr-calculator:before {
    content: "\f1c8";
    }
        .fi-rr-calendar-check:before {
    content: "\f1c9";
    }
        .fi-rr-calendar-clock:before {
    content: "\f1ca";
    }
        .fi-rr-calendar-exclamation:before {
    content: "\f1cb";
    }
        .fi-rr-calendar-lines-pen:before {
    content: "\f1cc";
    }
        .fi-rr-calendar-lines:before {
    content: "\f1cd";
    }
        .fi-rr-calendar-minus:before {
    content: "\f1ce";
    }
        .fi-rr-calendar-pen:before {
    content: "\f1cf";
    }
        .fi-rr-calendar-plus:before {
    content: "\f1d0";
    }
        .fi-rr-calendar:before {
    content: "\f1d1";
    }
        .fi-rr-calendars:before {
    content: "\f1d2";
    }
        .fi-rr-call-history:before {
    content: "\f1d3";
    }
        .fi-rr-call-incoming:before {
    content: "\f1d4";
    }
        .fi-rr-call-missed:before {
    content: "\f1d5";
    }
        .fi-rr-call-outgoing:before {
    content: "\f1d6";
    }
        .fi-rr-camcorder:before {
    content: "\f1d7";
    }
        .fi-rr-camera:before {
    content: "\f1d8";
    }
        .fi-rr-camping:before {
    content: "\f1d9";
    }
        .fi-rr-candy-alt:before {
    content: "\f1da";
    }
        .fi-rr-candy:before {
    content: "\f1db";
    }
        .fi-rr-canned-food:before {
    content: "\f1dc";
    }
        .fi-rr-car-alt:before {
    content: "\f1dd";
    }
        .fi-rr-car-battery:before {
    content: "\f1de";
    }
        .fi-rr-car-bolt:before {
    content: "\f1df";
    }
        .fi-rr-car-building:before {
    content: "\f1e0";
    }
        .fi-rr-car-bump:before {
    content: "\f1e1";
    }
        .fi-rr-car-bus:before {
    content: "\f1e2";
    }
        .fi-rr-car-circle-bolt:before {
    content: "\f1e3";
    }
        .fi-rr-car-crash:before {
    content: "\f1e4";
    }
        .fi-rr-car-garage:before {
    content: "\f1e5";
    }
        .fi-rr-car-mechanic:before {
    content: "\f1e6";
    }
        .fi-rr-car-side-bolt:before {
    content: "\f1e7";
    }
        .fi-rr-car-side:before {
    content: "\f1e8";
    }
        .fi-rr-car-tilt:before {
    content: "\f1e9";
    }
        .fi-rr-car-wash:before {
    content: "\f1ea";
    }
        .fi-rr-car:before {
    content: "\f1eb";
    }
        .fi-rr-caravan-alt:before {
    content: "\f1ec";
    }
        .fi-rr-caravan:before {
    content: "\f1ed";
    }
        .fi-rr-caret-circle-down:before {
    content: "\f1ee";
    }
        .fi-rr-caret-circle-right:before {
    content: "\f1ef";
    }
        .fi-rr-caret-circle-up:before {
    content: "\f1f0";
    }
        .fi-rr-caret-down:before {
    content: "\f1f1";
    }
        .fi-rr-caret-left:before {
    content: "\f1f2";
    }
        .fi-rr-caret-quare-up:before {
    content: "\f1f3";
    }
        .fi-rr-caret-right:before {
    content: "\f1f4";
    }
        .fi-rr-caret-square-down:before {
    content: "\f1f5";
    }
        .fi-rr-caret-square-left_1:before {
    content: "\f1f6";
    }
        .fi-rr-caret-square-left:before {
    content: "\f1f7";
    }
        .fi-rr-caret-square-right:before {
    content: "\f1f8";
    }
        .fi-rr-caret-up:before {
    content: "\f1f9";
    }
        .fi-rr-carrot:before {
    content: "\f1fa";
    }
        .fi-rr-cars:before {
    content: "\f1fb";
    }
        .fi-rr-cash-register:before {
    content: "\f1fc";
    }
        .fi-rr-chair-office:before {
    content: "\f1fd";
    }
        .fi-rr-chair:before {
    content: "\f1fe";
    }
        .fi-rr-charging-station:before {
    content: "\f1ff";
    }
        .fi-rr-chart-area:before {
    content: "\f200";
    }
        .fi-rr-chart-connected:before {
    content: "\f201";
    }
        .fi-rr-chart-histogram:before {
    content: "\f202";
    }
        .fi-rr-chart-line-up:before {
    content: "\f203";
    }
        .fi-rr-chart-network:before {
    content: "\f204";
    }
        .fi-rr-chart-pie-alt:before {
    content: "\f205";
    }
        .fi-rr-chart-pie:before {
    content: "\f206";
    }
        .fi-rr-chart-pyramid:before {
    content: "\f207";
    }
        .fi-rr-chart-scatter:before {
    content: "\f208";
    }
        .fi-rr-chart-set-theory:before {
    content: "\f209";
    }
        .fi-rr-chart-tree:before {
    content: "\f20a";
    }
        .fi-rr-chat-arrow-down:before {
    content: "\f20b";
    }
        .fi-rr-chat-arrow-grow:before {
    content: "\f20c";
    }
        .fi-rr-check:before {
    content: "\f20d";
    }
        .fi-rr-checkbox:before {
    content: "\f20e";
    }
        .fi-rr-cheese:before {
    content: "\f20f";
    }
        .fi-rr-cherry:before {
    content: "\f210";
    }
        .fi-rr-chess-bishop:before {
    content: "\f211";
    }
        .fi-rr-chess-board:before {
    content: "\f212";
    }
        .fi-rr-chess-clock-alt:before {
    content: "\f213";
    }
        .fi-rr-chess-clock:before {
    content: "\f214";
    }
        .fi-rr-chess-king-alt:before {
    content: "\f215";
    }
        .fi-rr-chess-king:before {
    content: "\f216";
    }
        .fi-rr-chess-knight-alt:before {
    content: "\f217";
    }
        .fi-rr-chess-knight:before {
    content: "\f218";
    }
        .fi-rr-chess-pawn-alt:before {
    content: "\f219";
    }
        .fi-rr-chess-piece:before {
    content: "\f21a";
    }
        .fi-rr-chess-queen-alt:before {
    content: "\f21b";
    }
        .fi-rr-chess-queen:before {
    content: "\f21c";
    }
        .fi-rr-chess-rook-alt:before {
    content: "\f21d";
    }
        .fi-rr-chess-rook:before {
    content: "\f21e";
    }
        .fi-rr-chess:before {
    content: "\f21f";
    }
        .fi-rr-chevron-double-down:before {
    content: "\f220";
    }
        .fi-rr-chevron-double-up:before {
    content: "\f221";
    }
        .fi-rr-child-head:before {
    content: "\f222";
    }
        .fi-rr-chocolate:before {
    content: "\f223";
    }
        .fi-rr-circle-envelope:before {
    content: "\f224";
    }
        .fi-rr-circle-phone-flip:before {
    content: "\f225";
    }
        .fi-rr-circle-phone-hangup:before {
    content: "\f226";
    }
        .fi-rr-circle-phone:before {
    content: "\f227";
    }
        .fi-rr-circle-small:before {
    content: "\f228";
    }
        .fi-rr-circle:before {
    content: "\f229";
    }
        .fi-rr-clip:before {
    content: "\f22a";
    }
        .fi-rr-clipboard-list-check:before {
    content: "\f22b";
    }
        .fi-rr-clipboard-list:before {
    content: "\f22c";
    }
        .fi-rr-clock-eight-thirty:before {
    content: "\f22d";
    }
        .fi-rr-clock-eleven-thirty:before {
    content: "\f22e";
    }
        .fi-rr-clock-eleven:before {
    content: "\f22f";
    }
        .fi-rr-clock-five-thirty:before {
    content: "\f230";
    }
        .fi-rr-clock-five:before {
    content: "\f231";
    }
        .fi-rr-clock-four-thirty:before {
    content: "\f232";
    }
        .fi-rr-clock-nine-thirty:before {
    content: "\f233";
    }
        .fi-rr-clock-nine:before {
    content: "\f234";
    }
        .fi-rr-clock-one-thirty:before {
    content: "\f235";
    }
        .fi-rr-clock-one:before {
    content: "\f236";
    }
        .fi-rr-clock-seven-thirty:before {
    content: "\f237";
    }
        .fi-rr-clock-seven:before {
    content: "\f238";
    }
        .fi-rr-clock-six-thirty:before {
    content: "\f239";
    }
        .fi-rr-clock-six:before {
    content: "\f23a";
    }
        .fi-rr-clock-ten-thirty:before {
    content: "\f23b";
    }
        .fi-rr-clock-ten:before {
    content: "\f23c";
    }
        .fi-rr-clock-three-thirty:before {
    content: "\f23d";
    }
        .fi-rr-clock-three:before {
    content: "\f23e";
    }
        .fi-rr-clock-twelve-thirty:before {
    content: "\f23f";
    }
        .fi-rr-clock-twelve:before {
    content: "\f240";
    }
        .fi-rr-clock-two-thirty:before {
    content: "\f241";
    }
        .fi-rr-clock-two:before {
    content: "\f242";
    }
        .fi-rr-clock:before {
    content: "\f243";
    }
        .fi-rr-cloud-check:before {
    content: "\f244";
    }
        .fi-rr-cloud-disabled:before {
    content: "\f245";
    }
        .fi-rr-cloud-download-alt:before {
    content: "\f246";
    }
        .fi-rr-cloud-download:before {
    content: "\f247";
    }
        .fi-rr-cloud-drizzle:before {
    content: "\f248";
    }
        .fi-rr-cloud-hail-mixed:before {
    content: "\f249";
    }
        .fi-rr-cloud-hail:before {
    content: "\f24a";
    }
        .fi-rr-cloud-moon-rain:before {
    content: "\f24b";
    }
        .fi-rr-cloud-moon:before {
    content: "\f24c";
    }
        .fi-rr-cloud-rain:before {
    content: "\f24d";
    }
        .fi-rr-cloud-rainbow:before {
    content: "\f24e";
    }
        .fi-rr-cloud-share:before {
    content: "\f24f";
    }
        .fi-rr-cloud-showers-heavy:before {
    content: "\f250";
    }
        .fi-rr-cloud-showers:before {
    content: "\f251";
    }
        .fi-rr-cloud-sleet:before {
    content: "\f252";
    }
        .fi-rr-cloud-snow:before {
    content: "\f253";
    }
        .fi-rr-cloud-sun-rain:before {
    content: "\f254";
    }
        .fi-rr-cloud-sun:before {
    content: "\f255";
    }
        .fi-rr-cloud-upload-alt:before {
    content: "\f256";
    }
        .fi-rr-cloud-upload:before {
    content: "\f257";
    }
        .fi-rr-cloud:before {
    content: "\f258";
    }
        .fi-rr-clouds-moon:before {
    content: "\f259";
    }
        .fi-rr-clouds-sun:before {
    content: "\f25a";
    }
        .fi-rr-clouds:before {
    content: "\f25b";
    }
        .fi-rr-club:before {
    content: "\f25c";
    }
        .fi-rr-cocktail-alt:before {
    content: "\f25d";
    }
        .fi-rr-cocktail:before {
    content: "\f25e";
    }
        .fi-rr-coffee-pot:before {
    content: "\f25f";
    }
        .fi-rr-coffee:before {
    content: "\f260";
    }
        .fi-rr-coin:before {
    content: "\f261";
    }
        .fi-rr-coins:before {
    content: "\f262";
    }
        .fi-rr-comet:before {
    content: "\f263";
    }
        .fi-rr-comment-alt-middle-top:before {
    content: "\f264";
    }
        .fi-rr-comment-alt-middle:before {
    content: "\f265";
    }
        .fi-rr-comment-alt:before {
    content: "\f266";
    }
        .fi-rr-comment-arrow-down:before {
    content: "\f267";
    }
        .fi-rr-comment-arrow-up-right:before {
    content: "\f268";
    }
        .fi-rr-comment-arrow-up:before {
    content: "\f269";
    }
        .fi-rr-comment-check:before {
    content: "\f26a";
    }
        .fi-rr-comment-code:before {
    content: "\f26b";
    }
        .fi-rr-comment-dollar:before {
    content: "\f26c";
    }
        .fi-rr-comment-exclamation:before {
    content: "\f26d";
    }
        .fi-rr-comment-heart:before {
    content: "\f26e";
    }
        .fi-rr-comment-image:before {
    content: "\f26f";
    }
        .fi-rr-comment-info:before {
    content: "\f270";
    }
        .fi-rr-comment-pen:before {
    content: "\f271";
    }
        .fi-rr-comment-question:before {
    content: "\f272";
    }
        .fi-rr-comment-quote:before {
    content: "\f273";
    }
        .fi-rr-comment-slash:before {
    content: "\f274";
    }
        .fi-rr-comment-sms:before {
    content: "\f275";
    }
        .fi-rr-comment-text:before {
    content: "\f276";
    }
        .fi-rr-comment-user:before {
    content: "\f277";
    }
        .fi-rr-comment-xmark:before {
    content: "\f278";
    }
        .fi-rr-comment:before {
    content: "\f279";
    }
        .fi-rr-comments-dollar:before {
    content: "\f27a";
    }
        .fi-rr-comments-question-check:before {
    content: "\f27b";
    }
        .fi-rr-comments-question:before {
    content: "\f27c";
    }
        .fi-rr-comments:before {
    content: "\f27d";
    }
        .fi-rr-compress-alt:before {
    content: "\f27e";
    }
        .fi-rr-compress:before {
    content: "\f27f";
    }
        .fi-rr-computer:before {
    content: "\f280";
    }
        .fi-rr-confetti:before {
    content: "\f281";
    }
        .fi-rr-cookie:before {
    content: "\f282";
    }
        .fi-rr-copy-alt:before {
    content: "\f283";
    }
        .fi-rr-copy:before {
    content: "\f284";
    }
        .fi-rr-copyright:before {
    content: "\f285";
    }
        .fi-rr-corn:before {
    content: "\f286";
    }
        .fi-rr-cow:before {
    content: "\f287";
    }
        .fi-rr-cream:before {
    content: "\f288";
    }
        .fi-rr-credit-card:before {
    content: "\f289";
    }
        .fi-rr-cricket:before {
    content: "\f28a";
    }
        .fi-rr-croissant:before {
    content: "\f28b";
    }
        .fi-rr-cross-circle:before {
    content: "\f28c";
    }
        .fi-rr-cross-small:before {
    content: "\f28d";
    }
        .fi-rr-cross:before {
    content: "\f28e";
    }
        .fi-rr-crown:before {
    content: "\f28f";
    }
        .fi-rr-crystal-ball:before {
    content: "\f290";
    }
        .fi-rr-cube:before {
    content: "\f291";
    }
        .fi-rr-cupcake:before {
    content: "\f292";
    }
        .fi-rr-curling:before {
    content: "\f293";
    }
        .fi-rr-cursor-finger:before {
    content: "\f294";
    }
        .fi-rr-cursor-plus:before {
    content: "\f295";
    }
        .fi-rr-cursor-text-alt:before {
    content: "\f296";
    }
        .fi-rr-cursor-text:before {
    content: "\f297";
    }
        .fi-rr-cursor:before {
    content: "\f298";
    }
        .fi-rr-d:before {
    content: "\f299";
    }
        .fi-rr-dart:before {
    content: "\f29a";
    }
        .fi-rr-dashboard:before {
    content: "\f29b";
    }
        .fi-rr-data-transfer:before {
    content: "\f29c";
    }
        .fi-rr-database:before {
    content: "\f29d";
    }
        .fi-rr-delete-document:before {
    content: "\f29e";
    }
        .fi-rr-delete-user:before {
    content: "\f29f";
    }
        .fi-rr-delete:before {
    content: "\f2a0";
    }
        .fi-rr-democrat:before {
    content: "\f2a1";
    }
        .fi-rr-dewpoint:before {
    content: "\f2a2";
    }
        .fi-rr-diamond:before {
    content: "\f2a3";
    }
        .fi-rr-dice-alt:before {
    content: "\f2a4";
    }
        .fi-rr-dice-d10:before {
    content: "\f2a5";
    }
        .fi-rr-dice-d12:before {
    content: "\f2a6";
    }
        .fi-rr-dice-d20:before {
    content: "\f2a7";
    }
        .fi-rr-dice-d4:before {
    content: "\f2a8";
    }
        .fi-rr-dice-d6:before {
    content: "\f2a9";
    }
        .fi-rr-dice-d8:before {
    content: "\f2aa";
    }
        .fi-rr-dice-four:before {
    content: "\f2ab";
    }
        .fi-rr-dice-one:before {
    content: "\f2ac";
    }
        .fi-rr-dice-six:before {
    content: "\f2ad";
    }
        .fi-rr-dice-three:before {
    content: "\f2ae";
    }
        .fi-rr-dice-two:before {
    content: "\f2af";
    }
        .fi-rr-dice:before {
    content: "\f2b0";
    }
        .fi-rr-diploma:before {
    content: "\f2b1";
    }
        .fi-rr-disco-ball:before {
    content: "\f2b2";
    }
        .fi-rr-disk:before {
    content: "\f2b3";
    }
        .fi-rr-dizzy:before {
    content: "\f2b4";
    }
        .fi-rr-doctor:before {
    content: "\f2b5";
    }
        .fi-rr-document-signed:before {
    content: "\f2b6";
    }
        .fi-rr-document:before {
    content: "\f2b7";
    }
        .fi-rr-dollar:before {
    content: "\f2b8";
    }
        .fi-rr-donate:before {
    content: "\f2b9";
    }
        .fi-rr-down-left-and-up-right-to-center:before {
    content: "\f2ba";
    }
        .fi-rr-download:before {
    content: "\f2bb";
    }
        .fi-rr-drafting-compass:before {
    content: "\f2bc";
    }
        .fi-rr-dreidel:before {
    content: "\f2bd";
    }
        .fi-rr-drink-alt:before {
    content: "\f2be";
    }
        .fi-rr-drumstick:before {
    content: "\f2bf";
    }
        .fi-rr-duplicate:before {
    content: "\f2c0";
    }
        .fi-rr-e-learning:before {
    content: "\f2c1";
    }
        .fi-rr-e:before {
    content: "\f2c2";
    }
        .fi-rr-earnings:before {
    content: "\f2c3";
    }
        .fi-rr-eclipse-alt:before {
    content: "\f2c4";
    }
        .fi-rr-eclipse:before {
    content: "\f2c5";
    }
        .fi-rr-edit-alt:before {
    content: "\f2c6";
    }
        .fi-rr-edit:before {
    content: "\f2c7";
    }
        .fi-rr-egg-fried:before {
    content: "\f2c8";
    }
        .fi-rr-egg:before {
    content: "\f2c9";
    }
        .fi-rr-engine-warning:before {
    content: "\f2ca";
    }
        .fi-rr-enter:before {
    content: "\f2cb";
    }
        .fi-rr-envelope-ban:before {
    content: "\f2cc";
    }
        .fi-rr-envelope-bulk:before {
    content: "\f2cd";
    }
        .fi-rr-envelope-download:before {
    content: "\f2ce";
    }
        .fi-rr-envelope-marker:before {
    content: "\f2cf";
    }
        .fi-rr-envelope-open-dollar:before {
    content: "\f2d0";
    }
        .fi-rr-envelope-open-text:before {
    content: "\f2d1";
    }
        .fi-rr-envelope-open:before {
    content: "\f2d2";
    }
        .fi-rr-envelope-plus:before {
    content: "\f2d3";
    }
        .fi-rr-envelope:before {
    content: "\f2d4";
    }
        .fi-rr-equality:before {
    content: "\f2d5";
    }
        .fi-rr-euro:before {
    content: "\f2d6";
    }
        .fi-rr-exchange-alt:before {
    content: "\f2d7";
    }
        .fi-rr-exchange:before {
    content: "\f2d8";
    }
        .fi-rr-exclamation:before {
    content: "\f2d9";
    }
        .fi-rr-exit:before {
    content: "\f2da";
    }
        .fi-rr-expand-arrows-alt:before {
    content: "\f2db";
    }
        .fi-rr-expand-arrows:before {
    content: "\f2dc";
    }
        .fi-rr-expand:before {
    content: "\f2dd";
    }
        .fi-rr-eye-crossed:before {
    content: "\f2de";
    }
        .fi-rr-eye-dropper:before {
    content: "\f2df";
    }
        .fi-rr-eye:before {
    content: "\f2e0";
    }
        .fi-rr-f:before {
    content: "\f2e1";
    }
        .fi-rr-feather:before {
    content: "\f2e2";
    }
        .fi-rr-ferris-wheel:before {
    content: "\f2e3";
    }
        .fi-rr-fighter-jet:before {
    content: "\f2e4";
    }
        .fi-rr-file-ai:before {
    content: "\f2e5";
    }
        .fi-rr-file-chart-line:before {
    content: "\f2e6";
    }
        .fi-rr-file-chart-pie:before {
    content: "\f2e7";
    }
        .fi-rr-file-eps:before {
    content: "\f2e8";
    }
        .fi-rr-file-invoice-dollar:before {
    content: "\f2e9";
    }
        .fi-rr-file-invoice:before {
    content: "\f2ea";
    }
        .fi-rr-file-psd:before {
    content: "\f2eb";
    }
        .fi-rr-file-spreadsheet:before {
    content: "\f2ec";
    }
        .fi-rr-file-video:before {
    content: "\f2ed";
    }
        .fi-rr-file:before {
    content: "\f2ee";
    }
        .fi-rr-fill:before {
    content: "\f2ef";
    }
        .fi-rr-film-slash:before {
    content: "\f2f0";
    }
        .fi-rr-film:before {
    content: "\f2f1";
    }
        .fi-rr-filter-slash:before {
    content: "\f2f2";
    }
        .fi-rr-filter:before {
    content: "\f2f3";
    }
        .fi-rr-filters:before {
    content: "\f2f4";
    }
        .fi-rr-fingerprint:before {
    content: "\f2f5";
    }
        .fi-rr-fish:before {
    content: "\f2f6";
    }
        .fi-rr-flag-alt:before {
    content: "\f2f7";
    }
        .fi-rr-flag-usa:before {
    content: "\f2f8";
    }
        .fi-rr-flag:before {
    content: "\f2f9";
    }
        .fi-rr-flame:before {
    content: "\f2fa";
    }
        .fi-rr-flip-horizontal:before {
    content: "\f2fb";
    }
        .fi-rr-flower-bouquet:before {
    content: "\f2fc";
    }
        .fi-rr-flower-tulip:before {
    content: "\f2fd";
    }
        .fi-rr-flower:before {
    content: "\f2fe";
    }
        .fi-rr-flushed:before {
    content: "\f2ff";
    }
        .fi-rr-fog:before {
    content: "\f300";
    }
        .fi-rr-folder-download:before {
    content: "\f301";
    }
        .fi-rr-folder-minus:before {
    content: "\f302";
    }
        .fi-rr-folder-times:before {
    content: "\f303";
    }
        .fi-rr-folder-tree:before {
    content: "\f304";
    }
        .fi-rr-folder-upload:before {
    content: "\f305";
    }
        .fi-rr-folder:before {
    content: "\f306";
    }
        .fi-rr-folders:before {
    content: "\f307";
    }
        .fi-rr-following:before {
    content: "\f308";
    }
        .fi-rr-football:before {
    content: "\f309";
    }
        .fi-rr-fork:before {
    content: "\f30a";
    }
        .fi-rr-form:before {
    content: "\f30b";
    }
        .fi-rr-forward:before {
    content: "\f30c";
    }
        .fi-rr-fox:before {
    content: "\f30d";
    }
        .fi-rr-french-fries:before {
    content: "\f30e";
    }
        .fi-rr-frown:before {
    content: "\f30f";
    }
        .fi-rr-ftp:before {
    content: "\f310";
    }
        .fi-rr-funnel-dollar:before {
    content: "\f311";
    }
        .fi-rr-g:before {
    content: "\f312";
    }
        .fi-rr-gallery:before {
    content: "\f313";
    }
        .fi-rr-game-board-alt:before {
    content: "\f314";
    }
        .fi-rr-gamepad:before {
    content: "\f315";
    }
        .fi-rr-garage-car:before {
    content: "\f316";
    }
        .fi-rr-garage-open:before {
    content: "\f317";
    }
        .fi-rr-garage:before {
    content: "\f318";
    }
        .fi-rr-garlic:before {
    content: "\f319";
    }
        .fi-rr-gas-pump-alt:before {
    content: "\f31a";
    }
        .fi-rr-gas-pump-slash:before {
    content: "\f31b";
    }
        .fi-rr-gas-pump:before {
    content: "\f31c";
    }
        .fi-rr-gem:before {
    content: "\f31d";
    }
        .fi-rr-gif:before {
    content: "\f31e";
    }
        .fi-rr-gift-card:before {
    content: "\f31f";
    }
        .fi-rr-gift:before {
    content: "\f320";
    }
        .fi-rr-gifts:before {
    content: "\f321";
    }
        .fi-rr-gingerbread-man:before {
    content: "\f322";
    }
        .fi-rr-glass-cheers:before {
    content: "\f323";
    }
        .fi-rr-glass:before {
    content: "\f324";
    }
        .fi-rr-glasses:before {
    content: "\f325";
    }
        .fi-rr-globe-alt:before {
    content: "\f326";
    }
        .fi-rr-globe:before {
    content: "\f327";
    }
        .fi-rr-golf-ball:before {
    content: "\f328";
    }
        .fi-rr-golf:before {
    content: "\f329";
    }
        .fi-rr-graduation-cap:before {
    content: "\f32a";
    }
        .fi-rr-grape:before {
    content: "\f32b";
    }
        .fi-rr-graphic-tablet:before {
    content: "\f32c";
    }
        .fi-rr-grid-alt:before {
    content: "\f32d";
    }
        .fi-rr-grid:before {
    content: "\f32e";
    }
        .fi-rr-grill:before {
    content: "\f32f";
    }
        .fi-rr-grimace:before {
    content: "\f330";
    }
        .fi-rr-grin-alt:before {
    content: "\f331";
    }
        .fi-rr-grin-beam-sweat:before {
    content: "\f332";
    }
        .fi-rr-grin-beam:before {
    content: "\f333";
    }
        .fi-rr-grin-hearts:before {
    content: "\f334";
    }
        .fi-rr-grin-squint-tears:before {
    content: "\f335";
    }
        .fi-rr-grin-squint:before {
    content: "\f336";
    }
        .fi-rr-grin-stars:before {
    content: "\f337";
    }
        .fi-rr-grin-tears:before {
    content: "\f338";
    }
        .fi-rr-grin-tongue-squint:before {
    content: "\f339";
    }
        .fi-rr-grin-tongue-wink:before {
    content: "\f33a";
    }
        .fi-rr-grin-tongue:before {
    content: "\f33b";
    }
        .fi-rr-grin-wink:before {
    content: "\f33c";
    }
        .fi-rr-grin:before {
    content: "\f33d";
    }
        .fi-rr-guitar:before {
    content: "\f33e";
    }
        .fi-rr-gym:before {
    content: "\f33f";
    }
        .fi-rr-h:before {
    content: "\f340";
    }
        .fi-rr-hamburger-soda:before {
    content: "\f341";
    }
        .fi-rr-hamburger:before {
    content: "\f342";
    }
        .fi-rr-hand-holding-box:before {
    content: "\f343";
    }
        .fi-rr-hand-holding-heart:before {
    content: "\f344";
    }
        .fi-rr-hand-holding-seeding:before {
    content: "\f345";
    }
        .fi-rr-hand:before {
    content: "\f346";
    }
        .fi-rr-handshake:before {
    content: "\f347";
    }
        .fi-rr-hastag:before {
    content: "\f348";
    }
        .fi-rr-hat-birthday:before {
    content: "\f349";
    }
        .fi-rr-hat-chef:before {
    content: "\f34a";
    }
        .fi-rr-head-side-thinking:before {
    content: "\f34b";
    }
        .fi-rr-headphones:before {
    content: "\f34c";
    }
        .fi-rr-headset:before {
    content: "\f34d";
    }
        .fi-rr-heart-arrow:before {
    content: "\f34e";
    }
        .fi-rr-heart:before {
    content: "\f34f";
    }
        .fi-rr-heat:before {
    content: "\f350";
    }
        .fi-rr-helicopter-side:before {
    content: "\f351";
    }
        .fi-rr-highlighter:before {
    content: "\f352";
    }
        .fi-rr-hiking:before {
    content: "\f353";
    }
        .fi-rr-hockey-puck:before {
    content: "\f354";
    }
        .fi-rr-hockey-sticks:before {
    content: "\f355";
    }
        .fi-rr-home-location-alt:before {
    content: "\f356";
    }
        .fi-rr-home-location:before {
    content: "\f357";
    }
        .fi-rr-home:before {
    content: "\f358";
    }
        .fi-rr-hotdog:before {
    content: "\f359";
    }
        .fi-rr-hourglass-end:before {
    content: "\f35a";
    }
        .fi-rr-hourglass:before {
    content: "\f35b";
    }
        .fi-rr-house-flood:before {
    content: "\f35c";
    }
        .fi-rr-hryvnia:before {
    content: "\f35d";
    }
        .fi-rr-humidity:before {
    content: "\f35e";
    }
        .fi-rr-hurricane:before {
    content: "\f35f";
    }
        .fi-rr-i:before {
    content: "\f360";
    }
        .fi-rr-ice-cream:before {
    content: "\f361";
    }
        .fi-rr-ice-skate:before {
    content: "\f362";
    }
        .fi-rr-id-badge:before {
    content: "\f363";
    }
        .fi-rr-inbox-in:before {
    content: "\f364";
    }
        .fi-rr-inbox-out:before {
    content: "\f365";
    }
        .fi-rr-inbox:before {
    content: "\f366";
    }
        .fi-rr-incognito:before {
    content: "\f367";
    }
        .fi-rr-indent:before {
    content: "\f368";
    }
        .fi-rr-infinity:before {
    content: "\f369";
    }
        .fi-rr-info:before {
    content: "\f36a";
    }
        .fi-rr-interactive:before {
    content: "\f36b";
    }
        .fi-rr-interlining:before {
    content: "\f36c";
    }
        .fi-rr-interrogation:before {
    content: "\f36d";
    }
        .fi-rr-italic:before {
    content: "\f36e";
    }
        .fi-rr-j:before {
    content: "\f36f";
    }
        .fi-rr-jam:before {
    content: "\f370";
    }
        .fi-rr-jpg:before {
    content: "\f371";
    }
        .fi-rr-k:before {
    content: "\f372";
    }
        .fi-rr-kerning:before {
    content: "\f373";
    }
        .fi-rr-key:before {
    content: "\f374";
    }
        .fi-rr-keyboard:before {
    content: "\f375";
    }
        .fi-rr-keynote:before {
    content: "\f376";
    }
        .fi-rr-kiss-beam:before {
    content: "\f377";
    }
        .fi-rr-kiss-wink-heart:before {
    content: "\f378";
    }
        .fi-rr-kiss:before {
    content: "\f379";
    }
        .fi-rr-kite:before {
    content: "\f37a";
    }
        .fi-rr-knife:before {
    content: "\f37b";
    }
        .fi-rr-l:before {
    content: "\f37c";
    }
        .fi-rr-label:before {
    content: "\f37d";
    }
        .fi-rr-laptop:before {
    content: "\f37e";
    }
        .fi-rr-lasso:before {
    content: "\f37f";
    }
        .fi-rr-laugh-beam:before {
    content: "\f380";
    }
        .fi-rr-laugh-squint:before {
    content: "\f381";
    }
        .fi-rr-laugh-wink:before {
    content: "\f382";
    }
        .fi-rr-laugh:before {
    content: "\f383";
    }
        .fi-rr-layer-minus:before {
    content: "\f384";
    }
        .fi-rr-layer-plus:before {
    content: "\f385";
    }
        .fi-rr-layers:before {
    content: "\f386";
    }
        .fi-rr-layout-fluid:before {
    content: "\f387";
    }
        .fi-rr-leaf:before {
    content: "\f388";
    }
        .fi-rr-lemon:before {
    content: "\f389";
    }
        .fi-rr-letter-case:before {
    content: "\f38a";
    }
        .fi-rr-lettuce:before {
    content: "\f38b";
    }
        .fi-rr-level-down-alt:before {
    content: "\f38c";
    }
        .fi-rr-level-down:before {
    content: "\f38d";
    }
        .fi-rr-level-up-alt:before {
    content: "\f38e";
    }
        .fi-rr-level-up:before {
    content: "\f38f";
    }
        .fi-rr-life-ring:before {
    content: "\f390";
    }
        .fi-rr-lightbulb-dollar:before {
    content: "\f391";
    }
        .fi-rr-line-width:before {
    content: "\f392";
    }
        .fi-rr-link-alt:before {
    content: "\f393";
    }
        .fi-rr-link-slash-alt:before {
    content: "\f394";
    }
        .fi-rr-link-slash:before {
    content: "\f395";
    }
        .fi-rr-link:before {
    content: "\f396";
    }
        .fi-rr-lipstick:before {
    content: "\f397";
    }
        .fi-rr-lira-sign:before {
    content: "\f398";
    }
        .fi-rr-list-check:before {
    content: "\f399";
    }
        .fi-rr-list:before {
    content: "\f39a";
    }
        .fi-rr-loading:before {
    content: "\f39b";
    }
        .fi-rr-location-alt:before {
    content: "\f39c";
    }
        .fi-rr-lock-alt:before {
    content: "\f39d";
    }
        .fi-rr-lock:before {
    content: "\f39e";
    }
        .fi-rr-luchador:before {
    content: "\f39f";
    }
        .fi-rr-luggage-rolling:before {
    content: "\f3a0";
    }
        .fi-rr-m:before {
    content: "\f3a1";
    }
        .fi-rr-magic-wand:before {
    content: "\f3a2";
    }
        .fi-rr-mailbox:before {
    content: "\f3a3";
    }
        .fi-rr-makeup-brush:before {
    content: "\f3a4";
    }
        .fi-rr-man-head:before {
    content: "\f3a5";
    }
        .fi-rr-map-marker-cross:before {
    content: "\f3a6";
    }
        .fi-rr-map-marker-home:before {
    content: "\f3a7";
    }
        .fi-rr-map-marker-minus:before {
    content: "\f3a8";
    }
        .fi-rr-map-marker-plus:before {
    content: "\f3a9";
    }
        .fi-rr-map-marker:before {
    content: "\f3aa";
    }
        .fi-rr-map:before {
    content: "\f3ab";
    }
        .fi-rr-marker-time:before {
    content: "\f3ac";
    }
        .fi-rr-marker:before {
    content: "\f3ad";
    }
        .fi-rr-mars-double:before {
    content: "\f3ae";
    }
        .fi-rr-mars:before {
    content: "\f3af";
    }
        .fi-rr-mask-carnival:before {
    content: "\f3b0";
    }
        .fi-rr-mask:before {
    content: "\f3b1";
    }
        .fi-rr-medicine:before {
    content: "\f3b2";
    }
        .fi-rr-megaphone:before {
    content: "\f3b3";
    }
        .fi-rr-meh-blank:before {
    content: "\f3b4";
    }
        .fi-rr-meh-rolling-eyes:before {
    content: "\f3b5";
    }
        .fi-rr-meh:before {
    content: "\f3b6";
    }
        .fi-rr-melon:before {
    content: "\f3b7";
    }
        .fi-rr-menu-burger:before {
    content: "\f3b8";
    }
        .fi-rr-menu-dots-vertical:before {
    content: "\f3b9";
    }
        .fi-rr-menu-dots:before {
    content: "\f3ba";
    }
        .fi-rr-meteor:before {
    content: "\f3bb";
    }
        .fi-rr-microphone-alt:before {
    content: "\f3bc";
    }
        .fi-rr-microphone:before {
    content: "\f3bd";
    }
        .fi-rr-mind-share:before {
    content: "\f3be";
    }
        .fi-rr-minus-small:before {
    content: "\f3bf";
    }
        .fi-rr-minus:before {
    content: "\f3c0";
    }
        .fi-rr-mobile-button:before {
    content: "\f3c1";
    }
        .fi-rr-mobile-notch:before {
    content: "\f3c2";
    }
        .fi-rr-mobile:before {
    content: "\f3c3";
    }
        .fi-rr-mode-landscape:before {
    content: "\f3c4";
    }
        .fi-rr-mode-portrait:before {
    content: "\f3c5";
    }
        .fi-rr-money-bill-wave-alt:before {
    content: "\f3c6";
    }
        .fi-rr-money-bill-wave:before {
    content: "\f3c7";
    }
        .fi-rr-money-check-edit-alt:before {
    content: "\f3c8";
    }
        .fi-rr-money-check-edit:before {
    content: "\f3c9";
    }
        .fi-rr-money-check:before {
    content: "\f3ca";
    }
        .fi-rr-money:before {
    content: "\f3cb";
    }
        .fi-rr-moon-stars:before {
    content: "\f3cc";
    }
        .fi-rr-moon:before {
    content: "\f3cd";
    }
        .fi-rr-motorcycle:before {
    content: "\f3ce";
    }
        .fi-rr-mountains:before {
    content: "\f3cf";
    }
        .fi-rr-mouse:before {
    content: "\f3d0";
    }
        .fi-rr-mug-alt:before {
    content: "\f3d1";
    }
        .fi-rr-mug-hot-alt:before {
    content: "\f3d2";
    }
        .fi-rr-mug-hot:before {
    content: "\f3d3";
    }
        .fi-rr-mug-tea:before {
    content: "\f3d4";
    }
        .fi-rr-mug:before {
    content: "\f3d5";
    }
        .fi-rr-mushroom:before {
    content: "\f3d6";
    }
        .fi-rr-music-alt:before {
    content: "\f3d7";
    }
        .fi-rr-music-file:before {
    content: "\f3d8";
    }
        .fi-rr-music:before {
    content: "\f3d9";
    }
        .fi-rr-n:before {
    content: "\f3da";
    }
        .fi-rr-navigation:before {
    content: "\f3db";
    }
        .fi-rr-network-cloud:before {
    content: "\f3dc";
    }
        .fi-rr-network:before {
    content: "\f3dd";
    }
        .fi-rr-noodles:before {
    content: "\f3de";
    }
        .fi-rr-notebook:before {
    content: "\f3df";
    }
        .fi-rr-o:before {
    content: "\f3e0";
    }
        .fi-rr-oil-can:before {
    content: "\f3e1";
    }
        .fi-rr-oil-temp:before {
    content: "\f3e2";
    }
        .fi-rr-olive-oil:before {
    content: "\f3e3";
    }
        .fi-rr-olives:before {
    content: "\f3e4";
    }
        .fi-rr-onion:before {
    content: "\f3e5";
    }
        .fi-rr-opacity:before {
    content: "\f3e6";
    }
        .fi-rr-overline:before {
    content: "\f3e7";
    }
        .fi-rr-p:before {
    content: "\f3e8";
    }
        .fi-rr-package:before {
    content: "\f3e9";
    }
        .fi-rr-page-break:before {
    content: "\f3ea";
    }
        .fi-rr-paint-brush:before {
    content: "\f3eb";
    }
        .fi-rr-paint-roller:before {
    content: "\f3ec";
    }
        .fi-rr-palette:before {
    content: "\f3ed";
    }
        .fi-rr-pan:before {
    content: "\f3ee";
    }
        .fi-rr-paper-plane:before {
    content: "\f3ef";
    }
        .fi-rr-password:before {
    content: "\f3f0";
    }
        .fi-rr-pause:before {
    content: "\f3f1";
    }
        .fi-rr-paw:before {
    content: "\f3f2";
    }
        .fi-rr-peach:before {
    content: "\f3f3";
    }
        .fi-rr-pencil-ruler:before {
    content: "\f3f4";
    }
        .fi-rr-pencil:before {
    content: "\f3f5";
    }
        .fi-rr-pennant:before {
    content: "\f3f6";
    }
        .fi-rr-people-poll:before {
    content: "\f3f7";
    }
        .fi-rr-pepper-hot:before {
    content: "\f3f8";
    }
        .fi-rr-pepper:before {
    content: "\f3f9";
    }
        .fi-rr-percentage:before {
    content: "\f3fa";
    }
        .fi-rr-pharmacy:before {
    content: "\f3fb";
    }
        .fi-rr-phone-call:before {
    content: "\f3fc";
    }
        .fi-rr-phone-cross:before {
    content: "\f3fd";
    }
        .fi-rr-phone-office:before {
    content: "\f3fe";
    }
        .fi-rr-phone-pause:before {
    content: "\f3ff";
    }
        .fi-rr-phone-slash:before {
    content: "\f400";
    }
        .fi-rr-photo-film-music:before {
    content: "\f401";
    }
        .fi-rr-photo-video:before {
    content: "\f402";
    }
        .fi-rr-physics:before {
    content: "\f403";
    }
        .fi-rr-picnic:before {
    content: "\f404";
    }
        .fi-rr-picture:before {
    content: "\f405";
    }
        .fi-rr-pie:before {
    content: "\f406";
    }
        .fi-rr-piece:before {
    content: "\f407";
    }
        .fi-rr-piggy-bank:before {
    content: "\f408";
    }
        .fi-rr-pineapple:before {
    content: "\f409";
    }
        .fi-rr-ping-pong:before {
    content: "\f40a";
    }
        .fi-rr-pizza-slice:before {
    content: "\f40b";
    }
        .fi-rr-plane-alt:before {
    content: "\f40c";
    }
        .fi-rr-plane:before {
    content: "\f40d";
    }
        .fi-rr-plate:before {
    content: "\f40e";
    }
        .fi-rr-play-alt:before {
    content: "\f40f";
    }
        .fi-rr-play-pause:before {
    content: "\f410";
    }
        .fi-rr-play:before {
    content: "\f411";
    }
        .fi-rr-playing-cards:before {
    content: "\f412";
    }
        .fi-rr-plus-small:before {
    content: "\f413";
    }
        .fi-rr-plus:before {
    content: "\f414";
    }
        .fi-rr-podium-star:before {
    content: "\f415";
    }
        .fi-rr-podium:before {
    content: "\f416";
    }
        .fi-rr-poker-chip:before {
    content: "\f417";
    }
        .fi-rr-poo:before {
    content: "\f418";
    }
        .fi-rr-popcorn:before {
    content: "\f419";
    }
        .fi-rr-portrait:before {
    content: "\f41a";
    }
        .fi-rr-pot:before {
    content: "\f41b";
    }
        .fi-rr-pound:before {
    content: "\f41c";
    }
        .fi-rr-power:before {
    content: "\f41d";
    }
        .fi-rr-presentation:before {
    content: "\f41e";
    }
        .fi-rr-print:before {
    content: "\f41f";
    }
        .fi-rr-protractor:before {
    content: "\f420";
    }
        .fi-rr-pulse:before {
    content: "\f421";
    }
        .fi-rr-pumpkin:before {
    content: "\f422";
    }
        .fi-rr-puzzle:before {
    content: "\f423";
    }
        .fi-rr-pyramid:before {
    content: "\f424";
    }
        .fi-rr-q:before {
    content: "\f425";
    }
        .fi-rr-question-square:before {
    content: "\f426";
    }
        .fi-rr-question:before {
    content: "\f427";
    }
        .fi-rr-quote-right:before {
    content: "\f428";
    }
        .fi-rr-r:before {
    content: "\f429";
    }
        .fi-rr-racquet:before {
    content: "\f42a";
    }
        .fi-rr-radish:before {
    content: "\f42b";
    }
        .fi-rr-rainbow:before {
    content: "\f42c";
    }
        .fi-rr-raindrops:before {
    content: "\f42d";
    }
        .fi-rr-rec:before {
    content: "\f42e";
    }
        .fi-rr-receipt:before {
    content: "\f42f";
    }
        .fi-rr-record-vinyl:before {
    content: "\f430";
    }
        .fi-rr-rectabgle-vertical:before {
    content: "\f431";
    }
        .fi-rr-rectangle-horizontal:before {
    content: "\f432";
    }
        .fi-rr-rectangle-panoramic:before {
    content: "\f433";
    }
        .fi-rr-recycle:before {
    content: "\f434";
    }
        .fi-rr-redo-alt:before {
    content: "\f435";
    }
        .fi-rr-redo:before {
    content: "\f436";
    }
        .fi-rr-reflect:before {
    content: "\f437";
    }
        .fi-rr-refresh:before {
    content: "\f438";
    }
        .fi-rr-registered:before {
    content: "\f439";
    }
        .fi-rr-remove-user:before {
    content: "\f43a";
    }
        .fi-rr-reply-all:before {
    content: "\f43b";
    }
        .fi-rr-republican:before {
    content: "\f43c";
    }
        .fi-rr-resize:before {
    content: "\f43d";
    }
        .fi-rr-resources:before {
    content: "\f43e";
    }
        .fi-rr-restaurant:before {
    content: "\f43f";
    }
        .fi-rr-rewind:before {
    content: "\f440";
    }
        .fi-rr-rhombus:before {
    content: "\f441";
    }
        .fi-rr-rings-wedding:before {
    content: "\f442";
    }
        .fi-rr-road:before {
    content: "\f443";
    }
        .fi-rr-rocket-lunch:before {
    content: "\f444";
    }
        .fi-rr-rocket:before {
    content: "\f445";
    }
        .fi-rr-room-service:before {
    content: "\f446";
    }
        .fi-rr-rotate-left:before {
    content: "\f447";
    }
        .fi-rr-rotate-right:before {
    content: "\f448";
    }
        .fi-rr-ruble-sign:before {
    content: "\f449";
    }
        .fi-rr-rugby:before {
    content: "\f44a";
    }
        .fi-rr-ruler-combined:before {
    content: "\f44b";
    }
        .fi-rr-ruler-horizontal:before {
    content: "\f44c";
    }
        .fi-rr-ruler-triangle:before {
    content: "\f44d";
    }
        .fi-rr-ruler-vertical:before {
    content: "\f44e";
    }
        .fi-rr-running:before {
    content: "\f44f";
    }
        .fi-rr-rupee-sign:before {
    content: "\f450";
    }
        .fi-rr-rv:before {
    content: "\f451";
    }
        .fi-rr-s:before {
    content: "\f452";
    }
        .fi-rr-sack-dollar:before {
    content: "\f453";
    }
        .fi-rr-sack:before {
    content: "\f454";
    }
        .fi-rr-sad-cry:before {
    content: "\f455";
    }
        .fi-rr-sad-tear:before {
    content: "\f456";
    }
        .fi-rr-sad:before {
    content: "\f457";
    }
        .fi-rr-salad:before {
    content: "\f458";
    }
        .fi-rr-salt-pepper:before {
    content: "\f459";
    }
        .fi-rr-sandwich:before {
    content: "\f45a";
    }
        .fi-rr-sauce:before {
    content: "\f45b";
    }
        .fi-rr-sausage:before {
    content: "\f45c";
    }
        .fi-rr-scale:before {
    content: "\f45d";
    }
        .fi-rr-school-bus:before {
    content: "\f45e";
    }
        .fi-rr-school:before {
    content: "\f45f";
    }
        .fi-rr-scissors:before {
    content: "\f460";
    }
        .fi-rr-screen:before {
    content: "\f461";
    }
        .fi-rr-search-alt:before {
    content: "\f462";
    }
        .fi-rr-search-dollar:before {
    content: "\f463";
    }
        .fi-rr-search-heart:before {
    content: "\f464";
    }
        .fi-rr-search-location:before {
    content: "\f465";
    }
        .fi-rr-search:before {
    content: "\f466";
    }
        .fi-rr-security:before {
    content: "\f467";
    }
        .fi-rr-sensor-alert:before {
    content: "\f468";
    }
        .fi-rr-sensor-fire:before {
    content: "\f469";
    }
        .fi-rr-sensor-on:before {
    content: "\f46a";
    }
        .fi-rr-sensor-smoke:before {
    content: "\f46b";
    }
        .fi-rr-sensor:before {
    content: "\f46c";
    }
        .fi-rr-settings-sliders:before {
    content: "\f46d";
    }
        .fi-rr-settings:before {
    content: "\f46e";
    }
        .fi-rr-share:before {
    content: "\f46f";
    }
        .fi-rr-shekel-sign:before {
    content: "\f470";
    }
        .fi-rr-shield-check:before {
    content: "\f471";
    }
        .fi-rr-shield-exclamation:before {
    content: "\f472";
    }
        .fi-rr-shield-interrogation:before {
    content: "\f473";
    }
        .fi-rr-shield-plus:before {
    content: "\f474";
    }
        .fi-rr-shield:before {
    content: "\f475";
    }
        .fi-rr-ship-side:before {
    content: "\f476";
    }
        .fi-rr-ship:before {
    content: "\f477";
    }
        .fi-rr-shop:before {
    content: "\f478";
    }
        .fi-rr-shopping-bag-add:before {
    content: "\f479";
    }
        .fi-rr-shopping-bag:before {
    content: "\f47a";
    }
        .fi-rr-shopping-basket:before {
    content: "\f47b";
    }
        .fi-rr-shopping-cart-add:before {
    content: "\f47c";
    }
        .fi-rr-shopping-cart-check:before {
    content: "\f47d";
    }
        .fi-rr-shopping-cart:before {
    content: "\f47e";
    }
        .fi-rr-shrimp:before {
    content: "\f47f";
    }
        .fi-rr-shuffle:before {
    content: "\f480";
    }
        .fi-rr-shuttle-van:before {
    content: "\f481";
    }
        .fi-rr-shuttlecock:before {
    content: "\f482";
    }
        .fi-rr-sign-in-alt:before {
    content: "\f483";
    }
        .fi-rr-sign-out-alt:before {
    content: "\f484";
    }
        .fi-rr-signal-alt-1:before {
    content: "\f485";
    }
        .fi-rr-signal-alt-2:before {
    content: "\f486";
    }
        .fi-rr-signal-alt:before {
    content: "\f487";
    }
        .fi-rr-skateboard:before {
    content: "\f488";
    }
        .fi-rr-skating:before {
    content: "\f489";
    }
        .fi-rr-skewer:before {
    content: "\f48a";
    }
        .fi-rr-ski-jump:before {
    content: "\f48b";
    }
        .fi-rr-ski-lift:before {
    content: "\f48c";
    }
        .fi-rr-skiing-nordic:before {
    content: "\f48d";
    }
        .fi-rr-skiing:before {
    content: "\f48e";
    }
        .fi-rr-sledding:before {
    content: "\f48f";
    }
        .fi-rr-sleigh:before {
    content: "\f490";
    }
        .fi-rr-smartphone:before {
    content: "\f491";
    }
        .fi-rr-smile-beam:before {
    content: "\f492";
    }
        .fi-rr-smile-wink:before {
    content: "\f493";
    }
        .fi-rr-smile:before {
    content: "\f494";
    }
        .fi-rr-smog:before {
    content: "\f495";
    }
        .fi-rr-smoke:before {
    content: "\f496";
    }
        .fi-rr-snow-blowing:before {
    content: "\f497";
    }
        .fi-rr-snowboarding:before {
    content: "\f498";
    }
        .fi-rr-snowflake:before {
    content: "\f499";
    }
        .fi-rr-snowflakes:before {
    content: "\f49a";
    }
        .fi-rr-snowmobile:before {
    content: "\f49b";
    }
        .fi-rr-snowplow:before {
    content: "\f49c";
    }
        .fi-rr-soap:before {
    content: "\f49d";
    }
        .fi-rr-social-network:before {
    content: "\f49e";
    }
        .fi-rr-sort-alpha-down-alt:before {
    content: "\f49f";
    }
        .fi-rr-sort-alpha-down:before {
    content: "\f4a0";
    }
        .fi-rr-sort-alpha-up-alt:before {
    content: "\f4a1";
    }
        .fi-rr-sort-alpha-up:before {
    content: "\f4a2";
    }
        .fi-rr-sort-alt:before {
    content: "\f4a3";
    }
        .fi-rr-sort-amount-down-alt:before {
    content: "\f4a4";
    }
        .fi-rr-sort-amount-down:before {
    content: "\f4a5";
    }
        .fi-rr-sort-amount-up-alt:before {
    content: "\f4a6";
    }
        .fi-rr-sort-amount-up:before {
    content: "\f4a7";
    }
        .fi-rr-sort-down:before {
    content: "\f4a8";
    }
        .fi-rr-sort-numeric-down-alt:before {
    content: "\f4a9";
    }
        .fi-rr-sort-numeric-down:before {
    content: "\f4aa";
    }
        .fi-rr-sort:before {
    content: "\f4ab";
    }
        .fi-rr-soup:before {
    content: "\f4ac";
    }
        .fi-rr-spa:before {
    content: "\f4ad";
    }
        .fi-rr-space-shuttle:before {
    content: "\f4ae";
    }
        .fi-rr-spade:before {
    content: "\f4af";
    }
        .fi-rr-sparkles:before {
    content: "\f4b0";
    }
        .fi-rr-speaker:before {
    content: "\f4b1";
    }
        .fi-rr-sphere:before {
    content: "\f4b2";
    }
        .fi-rr-spinner:before {
    content: "\f4b3";
    }
        .fi-rr-spoon:before {
    content: "\f4b4";
    }
        .fi-rr-sport:before {
    content: "\f4b5";
    }
        .fi-rr-square-root:before {
    content: "\f4b6";
    }
        .fi-rr-square:before {
    content: "\f4b7";
    }
        .fi-rr-stamp:before {
    content: "\f4b8";
    }
        .fi-rr-star-octogram:before {
    content: "\f4b9";
    }
        .fi-rr-star:before {
    content: "\f4ba";
    }
        .fi-rr-starfighter:before {
    content: "\f4bb";
    }
        .fi-rr-stars:before {
    content: "\f4bc";
    }
        .fi-rr-stats:before {
    content: "\f4bd";
    }
        .fi-rr-steak:before {
    content: "\f4be";
    }
        .fi-rr-steering-wheel:before {
    content: "\f4bf";
    }
        .fi-rr-stethoscope:before {
    content: "\f4c0";
    }
        .fi-rr-sticker:before {
    content: "\f4c1";
    }
        .fi-rr-stop:before {
    content: "\f4c2";
    }
        .fi-rr-stopwatch:before {
    content: "\f4c3";
    }
        .fi-rr-store-alt:before {
    content: "\f4c4";
    }
        .fi-rr-strawberry:before {
    content: "\f4c5";
    }
        .fi-rr-subtitles:before {
    content: "\f4c6";
    }
        .fi-rr-subway:before {
    content: "\f4c7";
    }
        .fi-rr-suitcase-alt:before {
    content: "\f4c8";
    }
        .fi-rr-summer:before {
    content: "\f4c9";
    }
        .fi-rr-sun:before {
    content: "\f4ca";
    }
        .fi-rr-sunrise-alt:before {
    content: "\f4cb";
    }
        .fi-rr-sunrise:before {
    content: "\f4cc";
    }
        .fi-rr-sunset:before {
    content: "\f4cd";
    }
        .fi-rr-surfing:before {
    content: "\f4ce";
    }
        .fi-rr-surprise:before {
    content: "\f4cf";
    }
        .fi-rr-sushi:before {
    content: "\f4d0";
    }
        .fi-rr-swimmer:before {
    content: "\f4d1";
    }
        .fi-rr-sword:before {
    content: "\f4d2";
    }
        .fi-rr-symbol:before {
    content: "\f4d3";
    }
        .fi-rr-syringe:before {
    content: "\f4d4";
    }
        .fi-rr-t:before {
    content: "\f4d5";
    }
        .fi-rr-tablet:before {
    content: "\f4d6";
    }
        .fi-rr-tachometer-alt-average:before {
    content: "\f4d7";
    }
        .fi-rr-tachometer-alt-fastest:before {
    content: "\f4d8";
    }
        .fi-rr-tachometer-alt-slow:before {
    content: "\f4d9";
    }
        .fi-rr-tachometer-alt-slowest:before {
    content: "\f4da";
    }
        .fi-rr-tachometer-average:before {
    content: "\f4db";
    }
        .fi-rr-tachometer-fast:before {
    content: "\f4dc";
    }
        .fi-rr-tachometer-fastest:before {
    content: "\f4dd";
    }
        .fi-rr-tachometer-slow:before {
    content: "\f4de";
    }
        .fi-rr-tachometer-slowest:before {
    content: "\f4df";
    }
        .fi-rr-tachometer:before {
    content: "\f4e0";
    }
        .fi-rr-taco:before {
    content: "\f4e1";
    }
        .fi-rr-tags:before {
    content: "\f4e2";
    }
        .fi-rr-tally:before {
    content: "\f4e3";
    }
        .fi-rr-target:before {
    content: "\f4e4";
    }
        .fi-rr-taxi:before {
    content: "\f4e5";
    }
        .fi-rr-temperature-down:before {
    content: "\f4e6";
    }
        .fi-rr-temperature-frigid:before {
    content: "\f4e7";
    }
        .fi-rr-temperature-high:before {
    content: "\f4e8";
    }
        .fi-rr-temperature-low:before {
    content: "\f4e9";
    }
        .fi-rr-temperature-up:before {
    content: "\f4ea";
    }
        .fi-rr-tenge:before {
    content: "\f4eb";
    }
        .fi-rr-tennis:before {
    content: "\f4ec";
    }
        .fi-rr-terrace:before {
    content: "\f4ed";
    }
        .fi-rr-test-tube:before {
    content: "\f4ee";
    }
        .fi-rr-test:before {
    content: "\f4ef";
    }
        .fi-rr-text-check:before {
    content: "\f4f0";
    }
        .fi-rr-text-slash:before {
    content: "\f4f1";
    }
        .fi-rr-text:before {
    content: "\f4f2";
    }
        .fi-rr-thermometer-half:before {
    content: "\f4f3";
    }
        .fi-rr-thumbtack:before {
    content: "\f4f4";
    }
        .fi-rr-thunderstorm-moon:before {
    content: "\f4f5";
    }
        .fi-rr-thunderstorm-sun:before {
    content: "\f4f6";
    }
        .fi-rr-thunderstorm:before {
    content: "\f4f7";
    }
        .fi-rr-ticket:before {
    content: "\f4f8";
    }
        .fi-rr-time-add:before {
    content: "\f4f9";
    }
        .fi-rr-time-check:before {
    content: "\f4fa";
    }
        .fi-rr-time-delete:before {
    content: "\f4fb";
    }
        .fi-rr-time-fast:before {
    content: "\f4fc";
    }
        .fi-rr-time-forward-sixty:before {
    content: "\f4fd";
    }
        .fi-rr-time-forward-ten:before {
    content: "\f4fe";
    }
        .fi-rr-time-forward:before {
    content: "\f4ff";
    }
        .fi-rr-time-half-past:before {
    content: "\f500";
    }
        .fi-rr-time-oclock:before {
    content: "\f501";
    }
        .fi-rr-time-past:before {
    content: "\f502";
    }
        .fi-rr-time-quarter-past:before {
    content: "\f503";
    }
        .fi-rr-time-quarter-to:before {
    content: "\f504";
    }
        .fi-rr-time-twenty-four:before {
    content: "\f505";
    }
        .fi-rr-tire-flat:before {
    content: "\f506";
    }
        .fi-rr-tire-pressure-warning:before {
    content: "\f507";
    }
        .fi-rr-tire-rugged:before {
    content: "\f508";
    }
        .fi-rr-tire:before {
    content: "\f509";
    }
        .fi-rr-tired:before {
    content: "\f50a";
    }
        .fi-rr-tomato:before {
    content: "\f50b";
    }
        .fi-rr-tool-box:before {
    content: "\f50c";
    }
        .fi-rr-tool-crop:before {
    content: "\f50d";
    }
        .fi-rr-tool-marquee:before {
    content: "\f50e";
    }
        .fi-rr-tooth:before {
    content: "\f50f";
    }
        .fi-rr-tornado:before {
    content: "\f510";
    }
        .fi-rr-tractor:before {
    content: "\f511";
    }
        .fi-rr-trailer:before {
    content: "\f512";
    }
        .fi-rr-train-side:before {
    content: "\f513";
    }
        .fi-rr-train:before {
    content: "\f514";
    }
        .fi-rr-tram:before {
    content: "\f515";
    }
        .fi-rr-transform:before {
    content: "\f516";
    }
        .fi-rr-trash:before {
    content: "\f517";
    }
        .fi-rr-treasure-chest:before {
    content: "\f518";
    }
        .fi-rr-treatment:before {
    content: "\f519";
    }
        .fi-rr-tree-christmas:before {
    content: "\f51a";
    }
        .fi-rr-tree:before {
    content: "\f51b";
    }
        .fi-rr-triangle:before {
    content: "\f51c";
    }
        .fi-rr-trophy:before {
    content: "\f51d";
    }
        .fi-rr-truck-container:before {
    content: "\f51e";
    }
        .fi-rr-truck-couch:before {
    content: "\f51f";
    }
        .fi-rr-truck-loading:before {
    content: "\f520";
    }
        .fi-rr-truck-monster:before {
    content: "\f521";
    }
        .fi-rr-truck-moving:before {
    content: "\f522";
    }
        .fi-rr-truck-pickup:before {
    content: "\f523";
    }
        .fi-rr-truck-plow:before {
    content: "\f524";
    }
        .fi-rr-truck-ramp:before {
    content: "\f525";
    }
        .fi-rr-truck-side:before {
    content: "\f526";
    }
        .fi-rr-tty:before {
    content: "\f527";
    }
        .fi-rr-turkey:before {
    content: "\f528";
    }
        .fi-rr-tv-music:before {
    content: "\f529";
    }
        .fi-rr-typewriter:before {
    content: "\f52a";
    }
        .fi-rr-u:before {
    content: "\f52b";
    }
        .fi-rr-umbrella:before {
    content: "\f52c";
    }
        .fi-rr-underline:before {
    content: "\f52d";
    }
        .fi-rr-undo-alt:before {
    content: "\f52e";
    }
        .fi-rr-undo:before {
    content: "\f52f";
    }
        .fi-rr-unlock:before {
    content: "\f530";
    }
        .fi-rr-upload:before {
    content: "\f531";
    }
        .fi-rr-usb-pendrive:before {
    content: "\f532";
    }
        .fi-rr-usd-circle:before {
    content: "\f533";
    }
        .fi-rr-usd-square:before {
    content: "\f534";
    }
        .fi-rr-user-add:before {
    content: "\f535";
    }
        .fi-rr-user-time:before {
    content: "\f536";
    }
        .fi-rr-user:before {
    content: "\f537";
    }
        .fi-rr-users-alt:before {
    content: "\f538";
    }
        .fi-rr-users:before {
    content: "\f539";
    }
        .fi-rr-utensils:before {
    content: "\f53a";
    }
        .fi-rr-v:before {
    content: "\f53b";
    }
        .fi-rr-vector-alt:before {
    content: "\f53c";
    }
        .fi-rr-vector:before {
    content: "\f53d";
    }
        .fi-rr-venus-double:before {
    content: "\f53e";
    }
        .fi-rr-venus-mars:before {
    content: "\f53f";
    }
        .fi-rr-venus:before {
    content: "\f540";
    }
        .fi-rr-video-arrow-down-left:before {
    content: "\f541";
    }
        .fi-rr-video-arrow-up-right:before {
    content: "\f542";
    }
        .fi-rr-video-camera-alt:before {
    content: "\f543";
    }
        .fi-rr-video-camera:before {
    content: "\f544";
    }
        .fi-rr-video-plus:before {
    content: "\f545";
    }
        .fi-rr-video-slash:before {
    content: "\f546";
    }
        .fi-rr-volcano:before {
    content: "\f547";
    }
        .fi-rr-volleyball:before {
    content: "\f548";
    }
        .fi-rr-volume:before {
    content: "\f549";
    }
        .fi-rr-w:before {
    content: "\f54a";
    }
        .fi-rr-wagon-covered:before {
    content: "\f54b";
    }
        .fi-rr-wallet:before {
    content: "\f54c";
    }
        .fi-rr-water-bottle:before {
    content: "\f54d";
    }
        .fi-rr-water-lower:before {
    content: "\f54e";
    }
        .fi-rr-water-rise:before {
    content: "\f54f";
    }
        .fi-rr-water:before {
    content: "\f550";
    }
        .fi-rr-watermelon:before {
    content: "\f551";
    }
        .fi-rr-wheat:before {
    content: "\f552";
    }
        .fi-rr-wheelchair:before {
    content: "\f553";
    }
        .fi-rr-whistle:before {
    content: "\f554";
    }
        .fi-rr-wifi-alt:before {
    content: "\f555";
    }
        .fi-rr-wind-warning:before {
    content: "\f556";
    }
        .fi-rr-wind:before {
    content: "\f557";
    }
        .fi-rr-windsock:before {
    content: "\f558";
    }
        .fi-rr-woman-head:before {
    content: "\f559";
    }
        .fi-rr-world:before {
    content: "\f55a";
    }
        .fi-rr-x:before {
    content: "\f55b";
    }
        .fi-rr-y:before {
    content: "\f55c";
    }
        .fi-rr-yen:before {
    content: "\f55d";
    }
        .fi-rr-z:before {
    content: "\f55e";
    }
        .fi-rr-zoom-in:before {
    content: "\f55f";
    }
        .fi-rr-zoom-out:before {
    content: "\f560";
    }
